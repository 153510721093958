<template>
  <div>
    <div class="row page-title align-items-center">
      <div class="col-sm-4 col-xl-6">
        <h4 class="mb-1 mt-0">Notifications</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100]"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <router-link
                    v-if="user && user.role === 'Admin'"
                    :to="{ name: 'admin.notifications.create' }"
                    class="btn btn-primary btn-sm mr-4"
                  >
                    <i class="uil uil-plus"></i> New Notification
                  </router-link>
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="datatable.queries.search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                :items="allNotificaiton"
                :fields="datatable.columns"
                :sort-by.sync="datatable.queries.sort_by"
                :sort-desc.sync="datatable.queries.sort_desc"
                :per-page="datatable.queries.per_page"
                :current-page="datatable.queries.page"
              >
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
                <template v-slot:cell(content)="data">
                  <div style="max-width:800px">
                    <strong>{{ data.item.title }}</strong>
                    <p>{{ data.item.content }}</p>
                  </div>
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | datetime($store.getters['auth/dateFormat'] + ' H:m') }}
                </template>
                <template v-slot:cell(actions)="data">
                  <b-button
                    v-if="!data.item.read"
                    variant="primary"
                    size="sm"
                    title="Mark as read"
                    :disabled="loadingRead !== null"
                    @click="read(data.item.id)"
                  >
                    <b-spinner v-if="loadingRead === data.item.id" small />
                    <i v-else class="uil uil-check"></i>
                  </b-button>
                  <b-button
                    class="ml-1"
                    variant="danger"
                    size="sm"
                    title="Delete Item"
                    :disabled="loadingDelete !== null"
                    @click="onShowDeleteConfirm(data.item)"
                  >
                    <b-spinner v-if="loadingDelete === data.item.id" small />
                    <i v-else class="uil uil-trash"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
            <div class="row mt-4">
              <div class="col">
                <div class="float-left mt-2">
                  Total {{ allNotificaiton.length }} notifications
                </div>
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="datatable.queries.page"
                      :total-rows="allNotificaiton.length"
                      :per-page="datatable.queries.per_page"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="showDeleteConfirmModal"
      title="Confirm Modal"
      title-class="font-18"
    >
      <h6>Are you sure you want to remove this item?</h6>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteConfirmModal = false"
          >Close</b-button
        >
        <b-button
          variant="danger"
          :disabled="loadingDelete !== null"
          @click="deleteItem(selectedItemId)"
        >
          <b-spinner v-if="loadingDelete === selectedItemId" small />
          <span v-else>Delete</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'content' },
          { key: 'created_at', sortable: true },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'created_at',
          sort_desc: false,
        },
      },
      loadingRead: null,
      loadingDelete: null,
      showDeleteConfirmModal: false,
      selectedItemId: null,
    }
  },

  computed: {
    allNotificaiton() {
      return this.$store.getters['user/allNotificaiton'] || []
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.getAllNotifications()
  },

  methods: {
    getAllNotifications() {
      this.$store.dispatch('user/getAllNotifications')
    },

    read(noticeId) {
      this.loadingRead = noticeId
      this.$store
        .dispatch('user/readNotification', noticeId)
        .then(() => {
          this.loadingRead = null
        })
        .catch(() => {
          this.loadingRead = null
        })
    },

    deleteItem(noticeId) {
      this.loadingDelete = noticeId
      this.$store
        .dispatch('user/deleteNotification', noticeId)
        .then(() => {
          this.showDeleteConfirmModal = false
          this.loadingDelete = null
        })
        .catch(() => {
          this.showDeleteConfirmModal = false
          this.loadingDelete = null
        })
    },

    onShowDeleteConfirm(item) {
      this.showDeleteConfirmModal = true
      this.selectedItemId = item.id
    },
  },
}
</script>
